.Title-Menu{
    display: flex;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
@media (max-width: 768px){
    .Title-Menu{
        flex-direction: column;
    } 
}