*{
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
    -webkit-font-smoothing: antialiased;
    outline: none;
}
code{
    font-family: Consolas,Monaco,"Andale Mono","Ubuntu Mono",monospace;
}
kbd{
    background: var(--bgBox);
    border-radius: 5px;
    color: var(--primaryFontColor);
    padding: 2px 5px;
}
body {
    background: var(--bgColor);
    color: var(--primaryFontColor);
    line-height: 1.75;
    margin: 0;
    overflow-x: hidden;
    transition: all 0.1s ease-out;
}
#root{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
h6{
    color: var(--primaryFontColor);
    font-weight: 900;
    line-height: 1.5;
    text-align: center;
}
h1{
    font-size: 50px;
}
h2{
    font-size: 40px;
}
h3{
    font-size: 30px;
}
p,
li,
ul{
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}
a{
    color: var(--blue);
    text-decoration: none;
}
button{
    background-color: var(--primaryFontColor);
    border: none;
    border-radius: 50px;
    color: var(--bgColor);
    cursor: pointer;
    display: block;
    font-size: 14px;
    padding: 10px 20px;
    text-align: center; 
    width: max-content;
}
button.secondary{
    border: 1px solid var(--secondaryFontColor);
    color: var(--primaryFontColor)
}
button.send{
    margin-left: auto;
}
button.more{
    background: none;
    border: none;
    color: var(--primaryFontColor);
    margin: auto;
    padding: 3px 0px;
}
button:disabled{
    opacity: 0.5;
}
img.emoji {
   border-radius: 0!important;
   height: 1em!important;
   width: 1em!important;
   vertical-align: -0.1em!important;
}
.Invisible{
    cursor: default;
    left: 0;
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
}
.Tooltip::before {
    background: var(--bgBox);
    box-shadow: var(--shadow-color);
    border-radius: 6px;
    color: var(--primaryFontColor);
    content: attr(data-tip);
    font-size: 12px;
    left: auto;
    line-height: 1.2;
    opacity: 0;
    padding: 6px;
    pointer-events: none;
    position: absolute;
    right: auto;
    transform: translate(0%, -25px);
    transition: 0.2s ease-in-out;
    width: max-content;
}
:root{
    --nmsLogo: #30363b;
    --bgColor: white;
    --bgColorRGB: 244, 248, 251;
    --boxShadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 5px 10px, rgb(15 15 15 / 40%) 0px 15px 40px;
    --shadow-color: rgb(22 24 27 / 22%) 0px 0px 0px 1px inset, rgb(0 0 0 / 25%) 0px 4px 4px -5px;
    --primaryFontColor: #30363b;
    --secondaryFontColor: #555; 
    --thirdFontColor: #828c99;
    --linkColor: #1e99e6;
    --bgBox: #f4f8fb;
    --lightBlue: #d2eafa;
    --blue: #1e99e6;
    --red: #eb5757;
    --pastelRed: #f39a9a;
    --pastelYellow: #fff1cf;
    --pastelGreen: #c8e6e0;
    --pastelPurple: #778dff;
    --greenMint: #48ac98;
    --yellow: #ffde87;
}
[data-theme = 'dark']{
    --bgColor: #262b2f;
    --bgColorRGB: 38, 43, 47;
    --primaryFontColor: #eef0f1;  
    --bgBox: #30363b;
}
::selection{
    background: var(--primaryFontColor);
    color: var(--bgColor);
}
@keyframes swirl_in{
    0%    {transform: rotate(0deg);}
    100%  {transform: rotate(360deg);}
}
@keyframes swirl_out{
    0%    {transform: rotate(360deg);}
    100%  {transform: rotate(0);}
}
@keyframes bounce{
    0%    {opacity: 0;   transform: scale(1,1);}
    50%   {opacity: 0.5; transform: scale(1.1, 1.1);}
    100%  {opacity: 1;   transform: scale(1,1);}
}
@keyframes bounce-back{
    0%    {opacity: 1;   transform: scale(1);}
    100%  {opacity: 0;   transform: scale(0.8);}
}
@keyframes tilt{
    50%  {transform: rotate(20deg);}
    100% {transform: rotate(0deg);}
}
@keyframes slide_up{
    0%    {bottom: -1000px;}
    50%   {bottom: -5px;}
    100%  {bottom: 0px;}
}
@keyframes slide_down {
    0%    {bottom: 0px;}
    50%   {bottom: -5px;}
    100%  {bottom: -1000px;}
}
@media(max-width: 768px){
    body{
        font-size: 14px;
        line-height: 1.75;
    }
}
@media (hover: hover) {
    .Tooltip:hover::before {
        opacity: 1;
        transform: translate(0%, -35px);
    }
}